import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Skills.css";
import { LearningObjectivesContext } from "./LearningObjectivesContext";
import { LanguageContext } from "./LanguageContext";

function Skills() {
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { learningObjectivesProgress, initializeObjectives } = useContext(LearningObjectivesContext);
  const { language } = useContext(LanguageContext); // Get the selected language from context
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch skills data from the backend
    const fetchSkills = async () => {
      try {
        const response = await fetch(`${backendUrl}/skills?language=${language}`, {
          headers: {
            "X-API-Key": process.env.REACT_APP_API_KEY,
          }
        });
        const data = await response.json();
        const groupedSkills = data.skills.reduce((acc, skill) => {
          const skill_level = skill.skill_level;
          if (!acc[skill_level]) {
            acc[skill_level] = [];
          }
          acc[skill_level].push(skill);
          return acc;
        }, {});
        setSkills(groupedSkills);

        // Initialize learning objectives progress
        data.skills.forEach(skill => {
          initializeObjectives(skill.name, skill.learning_objectives.length);
        });
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    fetchSkills();
  }, [initializeObjectives, language, backendUrl]);

  const handleSkillClick = (skill) => {
    setSelectedSkill(skill);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSkill(null);
  };

  const startChat = () => {
    navigate("/chat", { state: { selectedSkill } });
  };

  const toggleGroupCollapse = (level) => {
    setCollapsedGroups((prevCollapsedGroups) => ({
      ...prevCollapsedGroups,
      [level]: !prevCollapsedGroups[level],
    }));
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const calculateProgress = (skillName) => {
    const progress = learningObjectivesProgress[skillName] || [];
    const fulfilled = progress.filter(Boolean).length;
    const total = progress.length;
    return { fulfilled, total };
  };

  return (
    <div className="skills-container">
      <h1>Skills</h1>
      <p>Click on a skill to view more details and start a chat!</p>
      <div className="skills-grid">
        {Object.keys(skills).map((level) => (
          <div key={level} className="skill-level-group">
            <h2 onClick={() => toggleGroupCollapse(level)}
              data-icon={collapsedGroups[level] ? "➕" : "➖"}
            >
              {capitalizeFirstLetter(level)}
            </h2>
            {!collapsedGroups[level] && (
              <div className="skill-list">
                {skills[level].map((skill, index) => {
                  const { fulfilled, total } = calculateProgress(skill.name);
                  return (
                    <div
                      key={index}
                      className={`skill-block ${selectedSkill === skill ? "selected" : ""}`}
                      onClick={() => handleSkillClick(skill)}
                    >
                      <h3>{skill.name}</h3>
                      <p className={`skill-type ${skill.type.toLowerCase()}`}>{skill.type}</p>
                      <p className="skill-description">{skill.description}</p>
                      <div className="progress-bar">
                        <div
                          className="progress-bar-filled"
                          style={{ width: `${(fulfilled / total) * 100}%` }}
                        ></div>
                      </div>
                      <p className="progress-text">{`${fulfilled} / ${total} objectives fulfilled`}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>

      {isModalOpen && selectedSkill && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>{selectedSkill.name}</h2>
            <p className="skill-type">{selectedSkill.type}</p>
            <p className="skill-description">{selectedSkill.description}</p>
            <h3>Learning Objectives</h3>
            <ul>
              {selectedSkill.learning_objectives.map((objective, index) => (
                <li key={index}>
                  {objective} {learningObjectivesProgress[selectedSkill.name]?.[index] ? "☑" : "☐"}
                </li>
              ))}
            </ul>
            <button onClick={startChat}>Start Chat</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Skills;