import React, { createContext, useState, useCallback } from 'react';

export const LearningObjectivesContext = createContext();

export const LearningObjectivesProvider = ({ children }) => {
  const [learningObjectivesProgress, setLearningObjectivesProgress] = useState({});

  const initializeObjectives = useCallback((skillName, totalObjectives) => {
    setLearningObjectivesProgress((prevProgress) => {
      if (!prevProgress[skillName]) {
        return { ...prevProgress, [skillName]: Array(totalObjectives).fill(false) };
      }
      return prevProgress;
    });
  }, []);

  const toggleObjective = (skillName, objectiveIndex) => {
    setLearningObjectivesProgress((prevProgress) => {
      const skillProgress = prevProgress[skillName] || [];
      const newSkillProgress = [...skillProgress];
      newSkillProgress[objectiveIndex] = !newSkillProgress[objectiveIndex];
      return { ...prevProgress, [skillName]: newSkillProgress };
    });
  };

  const markObjectivesAsCompleted = (skillName, assessment) => {
    setLearningObjectivesProgress((prevProgress) => {
      const skillProgress = prevProgress[skillName] || [];
      const newSkillProgress = skillProgress.map((completed, index) =>
        assessment[index].outcome === "Fully Demonstrated" ? true : completed
      );
      return { ...prevProgress, [skillName]: newSkillProgress };
    });
  };

  return (
    <LearningObjectivesContext.Provider value={{ learningObjectivesProgress, toggleObjective, initializeObjectives, markObjectivesAsCompleted }}>
      {children}
    </LearningObjectivesContext.Provider>
  );
};