import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import "./Chat.css";
import { LearningObjectivesContext } from "./LearningObjectivesContext";
import { LanguageContext } from "./LanguageContext";

function Chat() {
  // State to track user input and chatbot responses
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  // Memoize selectedSkill to avoid unnecessary re-renders
  const selectedSkill = useMemo(() => location.state?.selectedSkill, [location.state?.selectedSkill]);
  const initialMessageSent = useRef(false);
  const chatBoxRef = useRef(null);
  const [isEndingChat, setIsEndingChat] = useState(false);
  const { markObjectivesAsCompleted } = useContext(LearningObjectivesContext);
  const { language } = useContext(LanguageContext); // Get the selected language from context
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const sendInitialMessage = async (message) => {
      const response = await fetch(`${backendUrl}/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apiKey
        },
        body: JSON.stringify({
          language: language,
          skill: selectedSkill,
          messages: [{ sender: "user", text: message }]
        }),
      });
      const data = await response.json();

      // Append the chatbot response to the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: data.response },
      ]);
    }

    console.log("Chat component rendering.");

    if (selectedSkill && !initialMessageSent.current) {
      // Start the conversation with a message about the selected skill
      const initialMessage = `I want to practise ${selectedSkill.name}.`;

      // Set initial message
      setMessages([{ sender: "user", text: initialMessage }]);

      // Send the initial message to the backend
      sendInitialMessage(initialMessage);
      initialMessageSent.current = true;
    }
  }, [language, selectedSkill, backendUrl, apiKey]);

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userInput.trim() === "") return;

    // Append the user input to the chat
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: userInput },
    ]);

    // Prepare the last 5 messages to send to the backend
    const lastFiveMessages = messages.slice(-4).concat({ sender: "user", text: userInput });

    // Send the user input to the backend
    const response = await fetch(`${backendUrl}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": apiKey
      },
      body: JSON.stringify({
        language: language,
        skill: selectedSkill,
        messages: lastFiveMessages
      }),
    });

    const data = await response.json();

    // Append the chatbot response to the chat
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "bot", text: data.response },
    ]);

    // Clear the input field
    setUserInput("");
  };

  // Function to handle ending the chat
  const handleEndChat = async () => {
    setIsEndingChat(true);
    try {
      const response = await fetch(`${backendUrl}/end-chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apiKey
        },
        body: JSON.stringify({
          language: language,
          skill: selectedSkill,
          messages: messages,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Chat ended successfully:", data);

        // Update learning objectives context
        markObjectivesAsCompleted(selectedSkill.name, data.assessment);

        // Navigate to the chat-end page with the assessment data
        navigate("/chat-end", { state: { assessment: data.assessment } });
      } else {
        console.error("Failed to end chat");
      }
    } catch (error) {
      console.error("Error ending chat:", error);
    }
    finally {
      setIsEndingChat(false);
    }
  };

  return (
    <div className="chat-container">
      {selectedSkill && (
        <div className="skill-name">{selectedSkill.name}</div>
      )}
      <div className="chat-box" ref={chatBoxRef}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender === "user" ? "user" : "bot"}`}>
            <strong>{message.sender}:</strong>
            <ReactMarkdown>
              {message.text}
            </ReactMarkdown>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type a message..."
        />
        <div className="button-container">
          <button className="send-button" type="submit">Send</button>
          <button className="end-chat-button" onClick={handleEndChat} disabled={isEndingChat}>
            {isEndingChat ? "Ending chat..." : "End chat"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Chat;