import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./ChatEnd.css";

function ChatEnd() {
  const location = useLocation();
  const navigate = useNavigate();
  const { assessment } = location.state || {};

  return (
    <div className="learning-outcomes-container">
      <h1>Learning Outcomes</h1>
      <ul>
        {assessment && assessment.map((item, index) => (
          <li key={index}>
            <strong>{item.objective}</strong>
            <div className="outcome">{item.outcome}</div>
          </li>
        ))}
      </ul>
      <p>Skill progress has been updated.</p>
      <button onClick={() => navigate("/")}>Go to Homepage</button>
    </div>
  );
}

export default ChatEnd;