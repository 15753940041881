import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Chat from "./Chat";
import Skills from "./Skills";
import ChatEnd from "./ChatEnd";
import "./App.css";
import LanguageSelector from "./LanguageSelector";


function App() {
  return (
    <Router>
      <div>
        <div className="header">
          <LanguageSelector />
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/skills">Skills</Link>
              </li>
            </ul>
          </nav>
        </div>
        <Routes>
          <Route path="/" element={<div className="welcome-message">Welcome to the Language Helper App!</div>} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat-end" element={<ChatEnd />} />
        </Routes>
      </div>
    </Router >
  );
}

export default App;
