import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from './LanguageContext';

const LanguageSelector = () => {
  const [languages, setLanguages] = useState([]);
  const { setLanguage } = useContext(LanguageContext);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch(`${backendUrl}/languages`, {
          headers: {
            'X-API-Key': apiKey
          }
        });
        const data = await response.json();
        setLanguages(data.languages);
        setLanguage(data.languages[0]);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchLanguages();
  }, [backendUrl, apiKey, setLanguage]);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <div>
      <select id="language" onChange={handleLanguageChange}>
        {languages.map((language) => (
          <option key={language} value={language}>
            {language}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;